import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { locale } from '../../../src/common/i18n'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
import route from '../../../src/common/utils/route'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const Landing4x4Es = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`images_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`images_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Neumáticos para 4x4" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          Un 4x4 cuenta con neumáticos que ofrecen unas prestaciones y un diseño
          adecuados para conseguir un agarre superior en cualquier tipo de
          terreno. La aparición durante la última década de modelos de coche más
          voluminosos junto a la moda de los SUV ha provocado que los neumáticos
          de 4x4 se hayan popularizado enormemente.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />
        <section className={styles.section}>
          <h2>¿Cómo saber qué ruedas escoger para un coche 4x4?</h2>
          <div>
            <p>
              Lo primero que debes tener en cuenta es que en coches 4x4 existen
              3 tipos diferentes de neumáticos. Existen opciones diseñadas y
              confeccionadas puramente para circular por carreteras Off-Road
              repletas de piedras, barro y arena… También existen modelos de
              neumático enfocados únicamente para circular por carreteras
              asfaltadas y opciones mixtas.
            </p>
            <ul>
              <li>
                <strong>Neumáticos de carretera: </strong> Están diseñados para
                ser usados principalmente sobre asfalto (80%) y sirven para
                circular por campo únicamente en momentos puntuales. Este modelo
                de neumático es ideal para propietarios de un coche 4x4 o SUV
                que circulan habitualmente en ciudad y realizan puntualmente
                escapadas a zonas con presencia de caminos de tierra.
              </li>
              <li>
                <strong>Neumáticos mixtos: </strong>Están contemplados para un
                uso muy polivalente, 50% campo y 50% zonas urbanas. Este
                neumático es ideal para personas que circulan por zonas Off Road
                y asfaltadas indistintamente de forma regular. Ofrecen una
                adherencia excepcional y una increíble facilidad de manejo en
                barro o arena.
              </li>
              <li>
                <strong>Neumáticas Off-Road: </strong>Contemplados para uso
                intensivo por zonas de montaña en un 80%. Son una clase de
                neumático muy resistente preparado para soportar la presión de
                los terrenos más exigentes. Se adaptan perfectamente a zonas con
                presencia de tierra, piedra, barro o arena. El diseño de su
                banda de la rodadura cuenta con un dibujo con grandes estrías
                que permiten un gran agarre.
              </li>
            </ul>
          </div>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            ¿Cómo saber qué tipo de neumático todoterreno necesito para mi coche
            4x4?
          </h2>
          <div>
            <p>
              Antes de nada, te recomendamos hacer el ejercicio de contabilizar
              el porcentaje de uso que le das a tu 4x4.{' '}
              <strong>
                No es lo mismo usar el coche para moverse por una ciudad como
                Las Palmas de Gran Canaria que usarlo para realizar rutas de
                guía turístico por el Teide.
              </strong>
            </p>
            <p>
              Poco podemos aconsejarte que no sepas ya en el caso de conducir
              por zonas urbanas, sin embargo, en el terreno Off Road sí que
              podemos darte algunos consejos de utilidad:
            </p>
            <ul>
              <li>
                <strong>Circula a velocidad moderadas. </strong>En zona Off Road
                los imprevistos son frecuentes, por este motivo se debe extremar
                la precaución. A mayor velocidad, más complicado es reaccionar a
                tiempo y, por tanto, la probabilidad de sufrir un accidente se
                incrementa enormemente.{' '}
              </li>
              <li>
                <strong>Circula a velocidad moderadas. </strong>En zona Off Road
                los imprevistos son frecuentes, por este motivo se debe extremar
                la precaución. A mayor velocidad, más complicado es reaccionar a
                tiempo y, por tanto, la probabilidad de sufrir un accidente se
                incrementa enormemente.
              </li>
              <li>
                <strong>Ten cuidado con el acelerador. </strong>Sobre todo, en
                zonas con arena o barro. La tracción en esta clase de terrenos
                es menos eficiente y si se comente el error de acelerar de forma
                brusca se incrementará la posibilidad de quedarse atascado.{' '}
              </li>
            </ul>
            <p>
              Si necesitas más información sobre Neumáticos Off-Road solicita
              cita previa en tu taller El Paso 2000 más cercano y nuestros
              especialistas en mecánica te ayudarán a resolver todas tus dudas.
            </p>
          </div>
        </section>
      </article>
    </div>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: object) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-4x4'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: 2,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: '4x4',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-4x4')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const Landing4x4 = ({ ...props }: Props) => <Landing4x4Es {...props} />

export default Landing4x4
